import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, TextField, Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from "@mui/material";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { fetchDocumentUrlAndOpenDialog, uploadDocumentToSharePoint, deleteDocumentFromSharePoint } from "../../services/sharepoint.service";
import { formatDate } from "../../utils/utilFunctions";
import { Add } from "@mui/icons-material";

type CVTableProps = {
  headers: string[],
  data: any[],
  accessToken: string,
  setCVData: any,
  setCvFileBlob: any,
  cvData: any[],
  cvFileBlob: any[],
  isEditMode: boolean
}

function CVTable({ headers, data, accessToken, setCVData, setCvFileBlob, cvData, cvFileBlob, isEditMode }: CVTableProps) {
  const [openAddCVDialog, setOpenAddCVDialog] = useState(false);
  const [cvLanguage, setCvLanguage] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { accounts } = useMsal();
  const username = accounts[0]?.username;

  const handleDelete = async (documentName: string) => {
    try {
      await deleteDocumentFromSharePoint(documentName, accessToken);
      setCVData(data.filter((row) => row.cvFile !== documentName));
    }
    catch (error) {
      console.error('Error deleting document', error);
    }
  };

  const handleOpenAddCVDialog = () => {
    setOpenAddCVDialog(true);
  };

  const handleCloseAddCVDialog = () => {
    setOpenAddCVDialog(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop()?.toLowerCase();

      if (fileType === 'pdf' || fileType === 'doc' || fileType === 'docx' || fileType === 'odt') {
        setSelectedFile(file);
      } else {
        alert('Invalid file type. Please select a PDF, DOC, DOCX, or ODT file.');
      }
    }
  };

  const handleUpload = async () => {
    if (!cvLanguage) {
      alert('Please enter a language or Variant Name for the CV.');
      return;
    }
    if (selectedFile) {
      //check if a file with the same name is already uploaded, if yes, do not upload
      if (Object.keys(cvData).length > 0) {
        const isDuplicate = cvData.some((row) => {
          if (row.cvFile === selectedFile.name) {
            alert('A file with the same name is already uploaded. Please rename the file and try again.');
            return true;
          }
          return false;
        });

        if (isDuplicate) {
          return;
        }
      }

      // Upload the file
      uploadDocumentToSharePoint(selectedFile, accessToken);

      // Simulate a file upload
      // After upload, close the dialog
      handleCloseAddCVDialog();

      //current data
      const currentDate = new Date();

      //get the fulltext of the selectedFile by data type
      const fileType = selectedFile.name.split('.').pop()?.toLowerCase();

      const updatedCVData = {
        cvFile: selectedFile.name, cvLanguage: cvLanguage, cvDate: currentDate.toLocaleDateString('en-CA'), cvUploadedBy: username,
        cvDataType: fileType
      };

      if (Object.keys(data).length === 0) {
        setCVData([updatedCVData]);
        setCvFileBlob([selectedFile]);
        return;
      }
      // Add the new row to the data state when data has already some rows
      if (Object.keys(data).length > 0) {
        setCVData([...data, updatedCVData]);
        setCvFileBlob([...cvFileBlob, selectedFile]);
      }
    }
  };

  return (
    <Grid>
      {data.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index}>
                    <Typography variant="subtitle2">{header}</Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography variant="subtitle2">Delete</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row, index) => (
                  <TableRow key={index}>
                    {headers.map((header) => (
                      <TableCell key={header}>
                        {header !== 'View CV' ? (
                          header === 'cvDate' ? formatDate(row[header]) :
                            header === 'viewCV' ? (
                              row.viewCV != undefined ? (
                                <Button color="primary" onClick={() => fetchDocumentUrlAndOpenDialog(row.viewCV, accessToken)}>
                                  View CV
                                </Button>) : null
                            ) : row[header]
                        ) : (
                          row.viewCV != undefined ? (
                            <Button color="primary" onClick={() => fetchDocumentUrlAndOpenDialog(row.viewCV, accessToken)}>
                              View CV
                            </Button>) : null
                        )}
                      </TableCell>
                    ))}
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          const isConfirmed = window.confirm("Are you sure you want to delete this file?");
                          if (isConfirmed) {
                            handleDelete(row.cvFile);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length + 1}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid container justifyContent="left" paddingTop={2}>
          <Button disabled={!isEditMode} startIcon={<Add />} variant="outlined" color="primary" onClick={handleOpenAddCVDialog}>
            Add CV
          </Button>
        </Grid>
      )}
      {data.length > 0 ? (
        <Grid container justifyContent="left" paddingTop={2}>

          <Button disabled={!isEditMode} startIcon={<Add />} variant="outlined" color="primary" onClick={handleOpenAddCVDialog}>
            Add another CV
          </Button>
        </Grid>
      ) : null}
      <Dialog open={openAddCVDialog} onClose={handleCloseAddCVDialog}>
        <DialogTitle>Add New CV</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="CV Language or Variant Name"
            type="text"
            fullWidth
            value={cvLanguage}
            onChange={(event) => setCvLanguage(event.target.value)}
          />
          <Input
            type="file"
            onChange={handleFileChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddCVDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

export default CVTable;