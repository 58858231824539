import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { postData } from "../../services/base.service";
import AutocompleteComponent from "../Shared/AutocompleteComponent";
import CVTable from "../Tables/CVTable";
import { deleteDocumentFromSharePoint } from "../../services/sharepoint.service";
import ConsentUpload from "../Shared/ConsentUpload";
import { Close, Edit, Save, Lock, Person, Stars, Add, Delete, TextSnippet, Check } from "@mui/icons-material";
import { useMsal, useAccount } from '@azure/msal-react';
import { requiredFieldsExpertsForm } from "../../types/dataTypes";
import ControlledTreeView from "../Shared/ControlledTreeView";
import { GenderOptions, WorkExperienceOptions } from "../../utils/consts";


type ExpertFormProps = {
  expertData?: any,
  type: string,
  setLoading: Function,
  accessToken: string,
  setDetailsDrawerOpen: Function,
  setFilters: Function,
}

function ExpertForm({ expertData = {}, type, setLoading, accessToken, setFilters, setDetailsDrawerOpen }: ExpertFormProps) {
  const [formData, setFormData] = useState(expertData);
  const [cvData, setCVData] = useState<any[]>(expertData.cvDetails || []);
  const [cvFileBlob, setCvFileBlob] = useState<any[]>([]);
  const [consentFileName, setConsentFileName] = useState(expertData.expertsDetails.consentFile || '');
  const [tabValue, setTabValue] = useState(0);
  const [isEditMode, setIsEditMode] = useState(type === 'add');
  const [saveFormData, setSaveFormData] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const toggleEditMode = () => {
    ;
    setIsEditMode(!isEditMode);
    //notify the user that the form is in edit mode
    alert(isEditMode ? 'Form is now in view mode. No changes possible now.' :
      'Form is now in edit mode. You can now edit the form and dont forget to hit save in the end.');
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({ ...prevData, expertsDetails: { ...prevData.expertsDetails, [name]: value } }));
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setFormData((prevData: any) => ({ ...prevData, expertsDetails: { ...prevData.expertsDetails, [name]: checked } }));
  };

  const handleYearsOfExperienceChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, expertsDetails: { ...prevData.expertsDetails, years_of_work_experience: newValue } }));
  };

  const handleGenderChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, expertsDetails: { ...prevData.expertsDetails, gender: newValue } }));
  };

  const handleLanguageChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, appliedOptions: { ...prevData.appliedOptions, languages: newValue } }));
  };

  const handleDonorChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, appliedOptions: { ...prevData.appliedOptions, donors: newValue } }));
  };

  const handleRegionChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, appliedOptions: { ...prevData.appliedOptions, regions: newValue } }));
  };

  const handleNationalityChange = (event: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, appliedOptions: { ...prevData.appliedOptions, nationalities: newValue } }));
  };

  const handleKeywordChange = (newValue: any) => { 
    if (!newValue) {
      console.error('New value is undefined');
      return;
    }  
    // Assuming newValue is an array of objects and you want to extract a specific property from each object
    const keywords = newValue.map((item: any) => item);
  
    setFormData((prevData: any) => ({
      ...prevData,
      appliedOptions: {
        ...prevData.appliedOptions,
        keywords: keywords,
      },
    }
    ));
  };

  const handleSave = async (type: string) => {
    console.log('Keywords:', formData.appliedOptions.keywords);

    setLoading(true);
    // check if required fields are filled
    if (!formvalidityCheck(formData.expertsDetails)) {
      alert('Please fill in all required fields First Name, Last Name and Email');
      setSaveFormData(true);
      setLoading(false);
      return;
    }

    formData.cvDetails = cvData;
    formData.expertsDetails.consentFile = consentFileName;
    if (Object.keys(formData.expertsDetails).length === 0 || formData.expertsDetails.firstName === '' || formData.expertsDetails.lastName === '') {
      alert('Please enter first name and last name');
      setLoading(false);
    }
    else if (Object.keys(formData.cvDetails).length === 0) {
      alert('Please upload a CV');
      setLoading(false);
    }
    else {
      if (type == 'add') {
        formData.expertsDetails.created_by = account?.username;
        formData.expertsDetails.created_at = new Date().toISOString();
        formData.expertsDetails.updated_at = null;
        formData.expertsDetails.updated_by = null;
      }
      else {
        formData.expertsDetails.updated_at = new Date().toISOString();
        formData.expertsDetails.updated_by = account?.username;
      }

      const expert = new FormData();

      if (Object.keys(cvFileBlob).length > 0) {
        cvFileBlob.forEach((file) => {
          expert.append('files', file);
        });
      }

      if (type == 'update') {
        //weird bug where region donor and language are listed when update #TODO check later
        delete formData.expertsDetails.region;
        delete formData.expertsDetails.donor;
        delete formData.expertsDetails.language;
        formData.cvDetails = formData.cvDetails.map((cvDetail: any) => {
          const { viewCV, ...rest } = cvDetail;
          return rest;
        });
      }

      expert.append('data', JSON.stringify(formData));

      await postData('experts/add', expert, 'json', undefined, false, 'none', '*/*').then(async (response: any) => {
        console.log(response.message);
        if (type == 'add') {
          alert(response.message); // Show the response text to the user
          setLoading(false);
        }
        else {
          alert('Expert updated successfully');
          setLoading(false);
        }
        setDetailsDrawerOpen(false);
        setLoading(false);
        setFilters({
          names: [],
          languages: [],
          regions: [],
          donors: [],
          keywords: [],
          fulltext: [],
          nationalities: [],
        });
      });
    }
  }


  const formvalidityCheck = (expertDetails: any): boolean => {
    let requiredFieldList = requiredFieldsExpertsForm;
    //check if all required fields are filled via a loop over the required fields and check if they are not undefined or empty
    //if they are, return false and log the error
    for (const field of requiredFieldList) {
      if (expertDetails[field as keyof any] === undefined || expertDetails[field as keyof any] === '') {
        console.error(`Validation error: ${field} is required.`);
        return false;
      }
    }
    return true;
  }

  const handleDelete = async (type: string, deleteCompletetly: boolean) => {
    if (deleteCompletetly) {
      const isConfirmed = window.confirm("Are you sure you want to delete this expert?");
      if (!isConfirmed) {
        return;
      }
    }

    setLoading(true);
    await postData('experts/delete', { expertID: formData.expertID }).then(async (response) => {
      if (response.cvFilenames) {
        for (let i = 0; i < response.cvFilenames.length; i++) {
          await deleteDocumentFromSharePoint(response.cvFilenames[i].cvlink, accessToken);
        }
      }
      if (type === 'update' && deleteCompletetly) {
        alert(response.message);
        setDetailsDrawerOpen(false);
        setLoading(false);
        setFilters({
          names: [],
          languages: [],
          regions: [],
          donors: [],
          keywords: [],
          fulltext: [],
        });
      }
    });
  };

  return (
    <div>
      <Grid container spacing={2} sx={{
        borderBottom: 1,
        borderColor: 'divider',
        position: 'sticky',
        top: 0,
        background: "white",
        zIndex: 1100,
        boxShadow: 1
      }}>

        <Grid container alignItems="center">
          <Grid item xs={6} md={6} style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
            {type === 'add' ? (
              <Typography paddingLeft={3} variant="h5">Add a new Expert</Typography>
            ) : null}
          </Grid>
          <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 10 }}>
            {type === 'update' && (
              <Button
                variant="outlined"
                startIcon={isEditMode ? <Lock /> : <Edit />}
                onClick={toggleEditMode}
                color="primary"
                style={{ marginRight: 10 }}
              >
                {isEditMode ? 'Lock' : 'Edit'}
              </Button>
            )}
            {(type === 'update' && isEditMode) || type === 'add' ? (
              <Button
                variant="outlined"
                color="primary"
                startIcon={isEditMode ? <Save /> : <Save />}
                style={{ marginRight: 10 }}
                onClick={() => handleSave(type)}
              >
                {'Save'}
              </Button>
            ) : null}
            {type === 'add' ? (
              <IconButton onClick={() => setDetailsDrawerOpen(false)}>
                <Close />
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label={<span style={{ fontWeight: 'bold' }}>General</span>} {...a11yProps(0)} />
            <Tab label={<span style={{ fontWeight: 'bold' }}>CV</span>} {...a11yProps(1)} />
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={tabValue} index={0}>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2}>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Stars />
              <Typography variant="h6">Experience</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteComponent
              options={formData.dropdownOptions.languages.map((language: any) => language.language)}
              value={formData.appliedOptions.languages != undefined ?
                (formData.appliedOptions.languages[0] === null ?
                  undefined : formData.appliedOptions.languages) : undefined}
              handleChange={handleLanguageChange}
              isMulti={true}
              label="Experts Languages"
              disabled={!isEditMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteComponent
              options={formData.dropdownOptions.donors.map((donor: any) => donor.donor)}
              value={formData.appliedOptions.donors != undefined ?
                (formData.appliedOptions.donors[0] === null ?
                  undefined : formData.appliedOptions.donors) : undefined} handleChange={handleDonorChange}
              isMulti={true}
              label="Donors"
              disabled={!isEditMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutocompleteComponent
              options={formData.dropdownOptions.regions.map((region: any) => region.region)}
              value={formData.appliedOptions.regions != undefined ?
                (formData.appliedOptions.regions[0] === null ?
                  undefined : formData.appliedOptions.regions) : undefined}
              handleChange={handleRegionChange}
              isMulti={true}
              label="Regions"
              disabled={!isEditMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControlledTreeView
              data={formData.dropdownOptions.keyWords != undefined ? formData.dropdownOptions.keyWords : []}
              handleChange={handleKeywordChange}
              mode="edit"
              selectedKeywords={formData.appliedOptions.keywords}
              enabled={isEditMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <AutocompleteComponent
              options={WorkExperienceOptions}
              value={formData.expertsDetails.years_of_work_experience || undefined}
              handleChange={handleYearsOfExperienceChange}
              isMulti={false}
              label="Years of Work Experience"
              disabled={!isEditMode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} paddingTop={2}>
          <Divider style={{ color: 'lightgrey' }} />
        </Grid>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2}>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Person />
              <Typography variant="h6">Contact</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField InputLabelProps={{ shrink: true }} label="First Name" name="firstName" value={formData.expertsDetails.firstName || ''}
              onChange={handleInputChange} required InputProps={{ readOnly: !isEditMode }} error={saveFormData && formData.expertsDetails.firstName === ''} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField InputLabelProps={{ shrink: true }} style={{ width: '100%' }} label="Last Name" name="lastName"
              value={formData.expertsDetails.lastName || ''} onChange={handleInputChange} required InputProps={{ readOnly: !isEditMode }}
              error={saveFormData && formData.expertsDetails.lastName === ''} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutocompleteComponent
                options={GenderOptions}
                value={formData.expertsDetails.gender || undefined}
                handleChange={handleGenderChange}
                isMulti={false}
                label="Gender"
                disabled={!isEditMode}
              />
            </Grid>
          <Grid item xs={12} md={4}>
             <AutocompleteComponent
                options={formData.dropdownOptions.countries.map((c: any) => c.Country) != undefined ? formData.dropdownOptions.countries.map((c: any) => c.Country) : []}
                value={formData.appliedOptions.nationalities != undefined ?
                  (formData.appliedOptions.nationalities[0] === null ?
                    undefined : formData.appliedOptions.nationalities) : undefined}
                handleChange={handleNationalityChange}
                isMulti={true}
                label="Nationality"
                disabled={!isEditMode}
              />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Birthdate" value={formData.expertsDetails.birthdate || ''} onChange={handleInputChange}
              type="date" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='birthdate' />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Email" value={formData.expertsDetails.email || ''} onChange={handleInputChange}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='email' type="email"
              required error={saveFormData && formData.expertsDetails.email === ''} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Phone Number" value={formData.expertsDetails.phone || ''} onChange={handleInputChange}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='phone' type="phone" />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} paddingTop={2}>
          <Divider style={{ color: 'lightgrey' }} />
        </Grid>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2}>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Add />
              <Typography variant="h6">Additional</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Remarks" value={formData.expertsDetails.remarks || ''} onChange={handleInputChange}
              InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='remarks'
              multiline rows={3} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField label="Expert Source" value={formData.expertsDetails.expert_source || ''} onChange={handleInputChange}
              multiline rows={3} InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='expert_source' />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel control={<Checkbox size='small' checked={formData.expertsDetails.favorite} indeterminate={formData.expertsDetails.favorite === undefined}
              style={{ color: saveFormData && formData.expertsDetails.favorite === undefined ? 'red' : 'inherit' }}
              onChange={handleCheckboxChange} name="favorite" />} label="Favorite" disabled={!isEditMode} />
          </Grid>
          {type === 'update' && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Expert Created By"
                  value={formData.expertsDetails.created_by || ''}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: !isEditMode }}
                  name='created_by'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Expert Created at"
                  value={formData.expertsDetails.created_at || ''}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ readOnly: !isEditMode }}
                  name='created_at'
                  disabled
                />
              </Grid>
              </>
            )}
              <Grid item xs={12} md={4}>
              <FormControlLabel control={<Checkbox size='small' checked={formData.expertsDetails.proposalWriter} indeterminate={formData.expertsDetails.proposalWriter === undefined}
                style={{ color: saveFormData && formData.expertsDetails.proposalWriter === undefined ? 'red' : 'inherit' }}
                onChange={handleCheckboxChange} name="proposalWriter" />} label="Proposal Writer" disabled={!isEditMode} />
              </Grid>    
          {type === 'update' && isEditMode && (
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<Delete />}
                onClick={() => handleDelete(type, true)}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Grid container spacing={3} margin={2}>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={1}>
              <TextSnippet />
              <Typography variant="h6">CV Versions</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={10}>
            <CVTable headers={['cvFile', 'cvLanguage', 'cvDate', 'cvUploadedBy', 'viewCV']}
              data={cvData} setCVData={setCVData} accessToken={accessToken} setCvFileBlob={setCvFileBlob}
              cvData={cvData} cvFileBlob={cvFileBlob} isEditMode={isEditMode} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} paddingTop={2}>
          <Divider style={{ color: 'lightgrey' }} />
        </Grid>
        <Grid container spacing={3} margin={2} marginTop={3}>
          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Check />
              <Typography variant="h6">Consent</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={5} justifyContent="right">
            <ConsentUpload setConsentFileName={setConsentFileName} accessToken={accessToken} type={type} isEditMode={isEditMode} />
          </Grid>
          <Grid item xs={12} sm={5} >
            <TextField
              variant="outlined"
              label={'Consent File Name'}
              name="consentFile"
              value={consentFileName || ''}
              disabled={true}
              inputProps={
                { readOnly: true }
              }
            />
          </Grid>
          <Grid item xs={12} sm={5} >
          <FormControlLabel control={<Checkbox size='small' checked={formData.expertsDetails.consent} indeterminate={formData.expertsDetails.consent === undefined}
                style={{ color: saveFormData && formData.expertsDetails.consent === undefined ? 'red' : 'inherit' }}
                onChange={handleCheckboxChange} name="consent" />} label="Consent given" disabled={!isEditMode} />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default ExpertForm;